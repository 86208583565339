import "slick-carousel";
import "../sass/home.scss";

var colors = [
	"fff",
	// "#2D373C",
	"#2D373C",
	// "#3D4545",
	// "#12988C",
	// "#f3830b",
	// "#55719b",
	// "#2f3729",
];

// var bgClass = ["has-phoenixBg", "has-cryptobg", "has-claimDustBg"];

$(".slider").on(
	"beforeChange",
	function (event, slick, currentSlide, nextSlide) {
		//change color here
		$(".slider").css("background-color", colors[nextSlide]);

		if (nextSlide === 0) {
			$(".slider").removeClass("has-cryptobg");
			$(".slider").removeClass("has-claimDustBg");
			$(".slider").addClass("has-phoenixBg");
		} else if (nextSlide === 1) {
			$(".slider").removeClass("has-phoenixBg");
			$(".slider").removeClass("has-claimDustBg");
			$(".slider").addClass("has-cryptobg");
		} else if (nextSlide === 2) {
			$(".slider").removeClass("has-phoenixBg");
			$(".slider").removeClass("has-cryptobg");
			$(".slider").addClass("has-claimDustBg");
		}
	}
);

$(".slider").on("init", function (event, slick) {
	$(".slider").addClass("has-phoenixBg");
	$(".slider").animate(
		{
			opacity: 1,
		},
		1000
	);
});

$(".slider").slick({
	dots: true,
	arrows: false,
	infinite: true,
	speed: 300,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 3000,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		// You can unslick at a given breakpoint now by adding:
		// settings: "unslick"
		// instead of a settings object
	],
});

$("#close-banner").on("click", () => {
	$("body").removeClass("os-banner-visible");
});

import news from "./news";

import image_business from "../images/image_business.png";

import bannerExplorer from "../images/banner-explorer.png";
import bannerForge from "../images/banner-forge.png";
import bannerLamelo from "../images/banner-lamelo.png";

import image_carousel from "../images/image_carousel.png";

import image_ethercards from "../images/image_ethercards.png";

import image_events from "../images/image_events.png";
