import "slick-carousel";

var blogPosts = [];
var blogCards = "";

(function mainScript() {
	"use strict";
	fetchBlogPosts();
})();

function fetchBlogPosts() {
	const url = "https://ethercards.ghost.io/ghost/api/v3/content/posts/";
	const params = {
		key: "dfcef1fe8427de771b79ee43a3",
		limit: "4",
		fullUrl: true,
	};

	$(".loader").show();
	$.ajax({
		method: "GET",
		url: url,
		data: params,
		success: (res) => {
			console.info(res);
			blogPosts = res.posts;
			renderBlogPosts();
			$(".loader").hide();
		},
		error: function (xhr, status, errorThrown) {
			console.log("Request Status: " + xhr.statusText);
			console.log("Status: " + status);
			console.log("Error: " + errorThrown);
		},
	});
}

function renderBlogPosts() {
	console.info(blogPosts);

	blogCards = blogPosts.map(function (post, index) {
		return `
      <div class="col-lg-3">
          <div class="card">
            <img class="card-img-top" src="${post.feature_image}" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">
                ${post.title}
              </h5>
              <p class="card-text">
                ${post.excerpt}
              </p>
              <a href="${post.url}" class="btn btn-purple">More</a>
            </div>
          </div>
      </div>
      `;
	});

	let blogContainer = $("#blogPosts");
	blogContainer.html(blogCards.join(""));

	$("#blogPosts").slick({
		arrows: false,
		prevArrow: false,
		nextArrow: false,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 2,
		autoplay: true,
		autoplaySpeed: 3000,

		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
				},
			},
		],
	});
}
